import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  contentBlockElements: document.querySelectorAll<HTMLElement>(
    '.content-block--form, .content-block--contact-form',
  ),
});

const onResetButtonClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLButtonElement)) return;

  const contentBlockElement =
    e.currentTarget.closest<HTMLElement>('.content-block');
  if (!contentBlockElement) return;

  contentBlockElement.classList.remove('content-block--form-completed');
  contentBlockElement.querySelector('.frm_message')?.remove();
  contentBlockElement
    .querySelector<
      HTMLInputElement | HTMLTextAreaElement
    >('.frm_form_field :is(input, textarea)')
    ?.focus();
};

export default defineModule(
  () => {
    const { contentBlockElements } = getElements();

    contentBlockElements.forEach((contentBlockElement) => {
      const resetButtonElement =
        contentBlockElement.querySelector<HTMLButtonElement>(
          '.form__reset-btn',
        );
      resetButtonElement?.addEventListener('click', onResetButtonClick);
    });
  },
  () => {
    const { contentBlockElements } = getElements();

    contentBlockElements.forEach((contentBlockElement) => {
      const resetButtonElement =
        contentBlockElement.querySelector<HTMLButtonElement>(
          '.form__reset-btn',
        );
      resetButtonElement?.removeEventListener('click', onResetButtonClick);
    });
  },
);
