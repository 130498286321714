import { defineModule } from '../utils/helpers';
import { isSingular } from '../utils/wordpress';

const getElements = () => ({
  portionSelectElements: document.querySelectorAll<HTMLSelectElement>(
    '[data-portions-select]',
  ),
  portionLabelElement: document.querySelector<HTMLElement>(
    '[data-portions-label]',
  ),
  ingredientsListElement:
    document.querySelector<HTMLElement>('[data-ingredients]'),
  singlePortionNoticeElement: document.querySelector<HTMLElement>(
    '[data-single-portion-notice]',
  ),
});

const setPortionLabel = (singular: boolean) => {
  const { portionLabelElement } = getElements();
  if (!portionLabelElement) return;

  const {
    portionsLabelSingular: singularLabel,
    portionsLabelPlural: pluralLabel,
  } = portionLabelElement.dataset;
  if (!singularLabel || !pluralLabel) return;

  portionLabelElement.textContent = singular ? singularLabel : pluralLabel;
};

const updateIngredientsAmount = (portions: number) => {
  const { ingredientsListElement } = getElements();
  if (!ingredientsListElement) return;

  const ingredientElements =
    ingredientsListElement.querySelectorAll<HTMLElement>('[data-ingredient]');
  ingredientElements.forEach((ingredientElement) => {
    const { ingredientSinglePortion: singlePortionAmount } =
      ingredientElement.dataset;
    if (!singlePortionAmount) return;

    const ingredientAmountElement =
      ingredientElement.querySelector<HTMLElement>('[data-ingredient-amount]');
    if (!ingredientAmountElement) return;

    ingredientAmountElement.textContent = `${Math.round(parseFloat(singlePortionAmount) * portions * 10000) / 10000}`;
  });
};

const updateSinglePortionNotice = (portions: number) => {
  const { singlePortionNoticeElement } = getElements();
  if (!singlePortionNoticeElement) return;

  singlePortionNoticeElement.classList.toggle('hidden', portions !== 1);
};

const onPortionChange = ({ currentTarget }: Event) => {
  if (!(currentTarget instanceof HTMLSelectElement)) {
    return;
  }

  setPortionLabel(currentTarget.value === '1');
  updateIngredientsAmount(parseInt(currentTarget.value));
  updateSinglePortionNotice(parseInt(currentTarget.value));

  const url = new URL(window.location.href);
  url.searchParams.set('portions', currentTarget.value);
  window.history.pushState({}, '', url.toString());
};

export default defineModule(
  () => {
    if (!isSingular('recipe')) return;

    const { portionSelectElements } = getElements();

    portionSelectElements.forEach((portionSelectElement) => {
      portionSelectElement.addEventListener('change', onPortionChange, {
        passive: true,
      });
    });
  },
  () => {
    const { portionSelectElements } = getElements();

    portionSelectElements.forEach((portionSelectElement) => {
      portionSelectElement.removeEventListener('change', onPortionChange);
    });
  },
);
