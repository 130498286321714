import { defineModule, nextTick } from '@/js/utils/helpers';

const resetPlaceholders = (formId: number) => {
  const formElement = document.getElementById(`gform_${formId}`);
  if (!formElement) return;

  nextTick(() => {
    formElement
      .querySelectorAll<
        HTMLInputElement | HTMLTextAreaElement
      >('input:not([type="checkbox"],[type="radio"],[type="hidden"]), textarea')
      .forEach((input) => {
        if (input.placeholder.trim().length) return;
        input.placeholder = ' ';
      });
  });
};

const onFormComplete = (event: JQuery.Event, formId: number) => {
  const formElement = document.getElementById(`gform_${formId}`);
  if (!formElement) return;

  // Reset placeholders
  resetPlaceholders(formId);

  // Add completed class to form
  formElement.classList.add('form--completed');

  // Add completed class to content block
  formElement
    .closest<HTMLElement>('.content-block')
    ?.classList.add('content-block--form-completed');

  // Add completed class to modal
  formElement.closest('.modal')?.classList.add('modal--form-completed');
};

const onFormSubmit = (event: JQuery.Event, formId: number) =>
  resetPlaceholders(formId);

export default defineModule(
  () => {
    jQuery(document).on('gform_confirmation_loaded', onFormComplete);
    jQuery(document).on('gform_post_render', onFormSubmit);
  },
  () => {
    jQuery(document).off('gform_confirmation_loaded', onFormComplete);
    jQuery(document).off('gform_post_render', onFormSubmit);
  },
);
