import { defineModule } from '../utils/helpers';
import type { createLikeButton } from '../utils/like-button';

const likeButtons: ReturnType<typeof createLikeButton>[] = [];

const getElements = () => ({
  likeElements: document.querySelectorAll<HTMLElement>('.like'),
});

export default defineModule(
  async () => {
    const { likeElements } = getElements();
    if (!likeElements.length) return;

    const { createLikeButton } = await import('../utils/like-button');

    likeElements.forEach((likeElement) => {
      const likeButtonElement =
        likeElement.querySelector<HTMLElement>('.like__btn');
      if (!likeButtonElement) return;

      const likeCountElement =
        likeElement.querySelector<HTMLElement>('.like__count');

      const likeButton = createLikeButton(likeButtonElement, {
        onClick: (liked) => {
          if (!likeCountElement) return;

          const count = parseInt(likeCountElement.textContent || '0');
          if (count === 0 && !liked) return;

          likeCountElement.textContent = `${count + (liked ? 1 : -1)}`;
        },
      });

      likeButtons.push(likeButton);
    });
  },
  () => {
    while (likeButtons.length > 0) {
      likeButtons.pop()?.destroy();
    }
  },
);
