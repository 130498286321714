import { createFocusTrap, type FocusTrap } from 'focus-trap';
import { BREAKPOINT_MEDIA_QUERIES } from '@/js/utils/breakpoints';
import { defineModule, nextTick } from '@/js/utils/helpers';
import { Overlay, toggleOverlay } from '@/js/utils/overlays';

let focusTrap: FocusTrap | null = null;

const getElements = () => ({
  navbar: document.querySelector<HTMLElement>('.navbar'),
  navButtons: document.querySelectorAll<HTMLButtonElement>(
    '.navbar button[aria-controls^="menu"]',
  ),
  navSubButtons: document.querySelectorAll<HTMLButtonElement>(
    '.navbar button[aria-controls^="sub-menu"]',
  ),
  menuToggler: document.querySelector<HTMLButtonElement>(
    '.navbar__nav > button[aria-controls]',
  ),
  emptySubLinks: [
    ...document.querySelectorAll<HTMLAnchorElement>(
      '.navbar .menu-item-has-children > .menu-item-link',
    ),
  ].filter((a) => !a.hasAttribute('href') || a.getAttribute('href') === '#'),
  subMenuTogglerElements: document.querySelectorAll<HTMLElement>(
    '.navbar .sub-menu__toggle',
  ),
  navSubItems: document.querySelectorAll<HTMLLIElement>(
    '.navbar .menu-item-has-children',
  ),
});

const toggleNavTransitions = (force: boolean) => {
  const { navbar } = getElements();
  if (!navbar) return;

  navbar.classList.toggle('navbar--no-transitions', force);
};

let closingTimeout: NodeJS.Timeout;
const toggleNavButton = (
  e: Event,
  force?: boolean,
  button?: HTMLButtonElement,
) => {
  const { navbar } = getElements();
  if (!navbar) return;

  const btn = button ?? (e.currentTarget as HTMLButtonElement);
  const expand = force ?? btn.ariaExpanded === 'false';
  const closing = !expand && btn.ariaExpanded === 'true';

  toggleOverlay(Overlay.MENU, expand);
  navbar.classList.toggle('menu--open', expand);

  clearTimeout(closingTimeout);
  if (closing) {
    navbar.classList.add('menu--closing');
    closingTimeout = setTimeout(() => {
      navbar.classList.remove('menu--closing');
    }, 500);
  }

  btn.ariaExpanded = String(expand);

  const { navSubButtons } = getElements();
  navSubButtons.forEach((sb) => {
    sb.ariaExpanded = String(false);
  });

  if (expand) {
    focusTrap = createFocusTrap(navbar, {
      initialFocus: navbar.querySelector<HTMLElement>('.menu-item')!,
      allowOutsideClick: false,
      escapeDeactivates: false,
    });
  } else {
    navbar.classList.remove('sub-menu--open');
    focusTrap?.deactivate();
  }
};

const toggleSubMenu = (menuLi: HTMLLIElement, expand: boolean) => {
  const subMenu = [...menuLi.children].find((child) =>
    child.classList.contains('sub-menu'),
  );
  const subId = subMenu?.id;
  const { navbar, navSubButtons } = getElements();

  if (!subId || !navbar) return;

  const openSubmenu = Array.from(navSubButtons).some(
    (btn) =>
      btn.ariaExpanded === 'true' &&
      btn.getAttribute('aria-controls') !== subId,
  );
  // toggleNavTransitions(openSubmenu); // is this needed?
  navbar.classList.toggle('sub-menu--open', expand);

  navSubButtons.forEach((btn) => {
    const expandButton = String(
      btn.getAttribute('aria-controls') === subId && expand,
    );
    btn.ariaExpanded = expandButton;
  });

  if (!openSubmenu) return;

  nextTick(() => {
    toggleNavTransitions(false);
  });
};

const clickNavSubButton = (e: Event) => {
  const btn = e.currentTarget as HTMLButtonElement;
  const expand = !(btn.ariaExpanded === 'true');
  const menuLi = btn.closest<HTMLLIElement>('.menu-item-has-children');

  if (!menuLi) return;

  toggleSubMenu(menuLi, expand);
};

const closeMenu = (e: Event) => {
  const { navbar, navButtons } = getElements();
  if (!navbar) return;

  if (e.type === 'mouseleave' && !BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  toggleNavButton(e, false, navButtons.item(0));
};

const navbarBreakpointChecker = (e: Event) => {
  const { navButtons } = getElements();
  if (!navButtons) return;

  toggleNavTransitions(true);

  closeMenu(e);

  nextTick(() => {
    toggleNavTransitions(false);
  });
};

const onEmptySubLinkClick = (e: Event) => {
  const link = e.currentTarget as HTMLAnchorElement;
  const btn = link.nextElementSibling as HTMLButtonElement;
  btn.click();
};

const onSubmenuToggle = (
  event: MouseEvent,
  force?: boolean,
  button?: HTMLButtonElement,
) => {
  if (!BREAKPOINT_MEDIA_QUERIES.lg.matches && event.type !== 'click') return;

  const btn = button ?? (event.currentTarget as HTMLButtonElement);

  event.preventDefault?.();

  // close any other sub-menu's
  const { subMenuTogglerElements } = getElements();
  [...subMenuTogglerElements]
    .filter((el) => el !== btn)
    .forEach((el) => el.setAttribute('aria-expanded', 'false'));

  const expand = force ?? btn.getAttribute('aria-expanded') === 'false';
  btn.setAttribute('aria-expanded', expand.toString());
};

const onPointerEnter = (event: PointerEvent) => {
  const li = event.currentTarget as HTMLLIElement;
  const btn = li.querySelector('.sub-menu__toggle') as HTMLButtonElement;

  onSubmenuToggle({ ...event, currentTarget: btn }, true, btn);
};

const onPointerLeave = (event: PointerEvent) => {
  return; // keep menu open after hovering away

  const li = event.currentTarget as HTMLLIElement;
  const btn = li.querySelector('.sub-menu__toggle') as HTMLButtonElement;

  onSubmenuToggle({ ...event, currentTarget: btn }, false, btn);
};

const onOutsideMenuClick = (event: Event) => {
  if (
    !BREAKPOINT_MEDIA_QUERIES.lg.matches ||
    !(event.target instanceof HTMLElement)
  ) {
    return;
  }

  const primaryMenu = event.target.closest('.menu--primary');
  if (primaryMenu) {
    return;
  }

  closeMenu(event);
};

export default defineModule(
  () => {
    const { navbar, navButtons, navSubButtons, emptySubLinks, navSubItems } =
      getElements();
    if (!navbar) return;

    navButtons.forEach((btn) =>
      btn.addEventListener('click', toggleNavButton, { passive: true }),
    );
    navSubButtons.forEach((btn) => {
      btn.addEventListener('click', clickNavSubButton, { passive: true });
    });
    emptySubLinks.forEach((a) =>
      a.addEventListener('click', onEmptySubLinkClick, { passive: true }),
    );

    navSubItems.forEach((li) => {
      li.addEventListener('pointerenter', onPointerEnter);
      li.addEventListener('pointerleave', onPointerLeave);
    });
    window.addEventListener('click', onOutsideMenuClick, { passive: true });

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { navbar, navButtons, navSubButtons, emptySubLinks, navSubItems } =
      getElements();
    if (!navbar) return;

    navButtons.forEach((btn) =>
      btn.removeEventListener('click', toggleNavButton),
    );
    navSubButtons.forEach((btn) => {
      btn.removeEventListener('click', clickNavSubButton);
    });
    emptySubLinks.forEach((a) =>
      a.removeEventListener('click', onEmptySubLinkClick),
    );
    navSubItems.forEach((li) => {
      li.addEventListener('pointerenter', onPointerEnter);
      li.addEventListener('pointerleave', onPointerLeave);
    });
    window.removeEventListener('click', onOutsideMenuClick);

    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );

    toggleOverlay(Overlay.MENU, false);
  },
);
