import { defineModule } from '../utils/helpers';

const getElements = (commentId: string | null = null) => ({
  replyFormElements: document.querySelectorAll<HTMLFormElement>(
    '[id^="reply-form-"]',
  ),
  commentButtonElements:
    document.querySelectorAll<HTMLButtonElement>('[data-comment-id]'),
  closeButtonElements:
    document.querySelectorAll<HTMLButtonElement>('.close-reply-form'),
  singleReplyFormElement:
    commentId && document.getElementById(`reply-form-${commentId}`),
  textareaElement:
    commentId && document.querySelector(`#reply-form-${commentId} textarea`),
});

const hideReplyFormElements = (): void => {
  const { replyFormElements } = getElements();
  if (!replyFormElements) return;

  replyFormElements.forEach((form) => form.classList.add('hidden'));
};

const keydownHandler = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    hideReplyFormElements();
  }
};

export default defineModule(
  () => {
    const { commentButtonElements, closeButtonElements } = getElements();

    commentButtonElements.forEach((button) => {
      button.addEventListener('click', () => {
        hideReplyFormElements();

        const commentId = button.getAttribute('data-comment-id');
        const { singleReplyFormElement, textareaElement } =
          getElements(commentId);

        if (!singleReplyFormElement) {
          return;
        }

        singleReplyFormElement.classList.toggle('hidden');
        singleReplyFormElement.scrollIntoView({ behavior: 'smooth' });

        if (!textareaElement) {
          return;
        }

        (textareaElement as HTMLTextAreaElement).focus();
      });
    });

    closeButtonElements.forEach((button) => {
      button.addEventListener('click', hideReplyFormElements);
    });

    document.addEventListener('keydown', keydownHandler);
  },
  () => {
    const { closeButtonElements } = getElements();

    closeButtonElements.forEach((button) => {
      button.removeEventListener('click', hideReplyFormElements);
    });

    document.removeEventListener('keydown', keydownHandler);
  },
);
