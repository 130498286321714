import { defineModule } from '@/js/utils/helpers';

const getElements = () => ({
  inputElements: document.querySelectorAll<
    HTMLInputElement | HTMLTextAreaElement
  >('.gfield :where(input:not([type="checkbox"], [type="radio"]), textarea)'),
});

export default defineModule(() => {
  const { inputElements } = getElements();

  inputElements.forEach((inputElement) => {
    if (inputElement.hasAttribute('placeholder')) return;

    // Makes the floating label work
    inputElement.setAttribute('placeholder', ' ');
  });
});
